export default [
  {
    days: 1,
    price: 4.99
  },
  {
    days: 7,
    price: 19.99
  },
  {
    days: 30,
    price: 69.99
  },
  {
    days: 60,
    price: 99.99
  },
  {
    days: 120,
    price: 119.99
  },
  {
    days: 180,
    price: 139.99
  }
]

export const info = {
  type: 'Replenish Paid Access'
}
