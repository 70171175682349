import { graphql, useStaticQuery } from 'gatsby'

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        allQuestion {
          edges {
            node {
              id
              url
            }
          }
        }
      }
    `
  )

  const allQuestion = data.allQuestion.edges
  const random = Math.floor(Math.random() * allQuestion.length)
  const randomQuestion = allQuestion[random].node

  return randomQuestion
}
