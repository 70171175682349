import * as actionTypes from './actionTypes'
import * as actions from './index'
import axios from '../../utils/axios'

export const userSave = (userId, token, name, email) => {
  return dispatch => {
    const now = Date.now()

    const userData = {
      userName: name,
      email: email,
      registered: now,
      paidAccessUntil: now,
      freeQuestions: 20
    }

    axios
      .put(`/users/${userId}.json?auth=` + token, userData)
      .then(res => {
        dispatch(
          userSaveSuccess(
            userId,
            res.data.userName,
            res.data.registered,
            res.data.paidAccessUntil,
            res.data.email,
            res.data.freeQuestions
          )
        )
      })
      .catch(err => {
        dispatch(actions.authFail(err.message))
        dispatch(actions.logout())
      })
  }
}

export const userUpdate = (
  userId,
  token,
  email,
  name,
  registered,
  paidAccessUntil
) => {
  return dispatch => {
    const data = {
      email: email,
      userName: name,
      registered: registered,
      paidAccessUntil: paidAccessUntil
    }

    axios
      .put(`/users/${userId}.json?auth=${token}`, data)
      .then(res => {
        dispatch(userUpdateSuccess(res.data.email, res.data.userName))
      })
      .catch(err => {
        dispatch(actions.authFail(err.message))
        dispatch(actions.logout())
      })
  }
}

export const userFetch = (userId, token) => {
  return dispatch => {
    axios
      .get(`/users/${userId}.json?auth=` + token)
      .then(res => {
        dispatch(
          userSaveSuccess(
            userId,
            res.data.userName,
            res.data.registered,
            res.data.paidAccessUntil,
            res.data.email,
            res.data.freeQuestions
          )
        )
      })
      .catch(err => {
        dispatch(actions.authFail(err))
      })
  }
}

export const userSaveSuccess = (
  id,
  name,
  registered,
  paidAccessUntil,
  email,
  freeQuestions
) => {
  return {
    type: actionTypes.USER_SAVE_SUCCESS,
    id: id,
    name: name,
    email: email,
    registered: registered,
    paidAccessUntil: paidAccessUntil,
    freeQuestions: freeQuestions
  }
}

export const userUpdateSuccess = (email, name) => {
  return {
    type: actionTypes.USER_UPDATE_SUCCESS,
    email: email,
    name: name
  }
}

export const userReplenishSession = (id, days) => {
  return {
    type: actionTypes.USER_REPLENISH_SESSION,
    replenishSession: id,
    days: days
  }
}

export const userReplenishPaidAccess = (
  userId,
  token,
  days,
  paidAccessUntil
) => {
  return dispatch => {
    let now = Date.now()
    const addTime = days * 24 * 60 * 60 * 1000

    if (paidAccessUntil > now) now = paidAccessUntil

    axios
      .put(`/users/${userId}/paidAccessUntil.json?auth=${token}`, now + addTime)
      .then(res => {
        dispatch(userReplenishPaidAccessSuccess(res.data))
        dispatch(actions.paymentSave(userId, token, Date.now(), days))
      })
      .catch(err => {
        console.log(`[userReplenishPaidAccess] err`, err)

        dispatch(userReplenishPaidAccessFail())
      })
  }
}

export const userReplenishPaidAccessSuccess = paidAccessUntil => {
  return {
    type: actionTypes.USER_REPLENISH_PAID_ACCESS_SUCCESS,
    paidAccessUntil: paidAccessUntil
  }
}

export const userReplenishPaidAccessFail = () => {
  return {
    type: actionTypes.USER_REPLENISH_PAID_ACCESS_FAIL
  }
}

export const userReplenishPaidAccessEraseMessage = () => {
  return {
    type: actionTypes.USER_REPLENISH_PAID_ACCESS_ERASE_MESSAGE
  }
}

export const userUsedFreeQuestion = () => {
  return {
    type: actionTypes.USER_USED_FREE_QUESTION
  }
}

export const userUsedAllFreeQuestions = (userId, token) => {
  return () => {
    axios
      .put(`/users/${userId}/freeQuestions.json?auth=` + token, 0)
      .then(_ => {})
      .catch(err => console.log(`[userUsedAllFreeQuestions] err`, err))
  }
}
