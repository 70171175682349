import * as actionTypes from './actionTypes'
import axios from 'axios'
import * as actions from './index'
import { loadState } from '../../utils/connectivity/localStorage'

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

export const authSuccess = (token, userId, expirationDate, refreshToken) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    expirationDate: expirationDate,
    refreshToken: refreshToken
  }
}

// TODO: refactor signup and login

export const signup = (name, email, password) => {
  return dispatch => {
    dispatch(authStart())

    const authData = {
      email: email,
      password: password,
      returnSecureToken: true
    }

    const url =
      'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyBHfcEH7VA616uzPkDIi8pm6hZvwxOvFzk'

    axios
      .post(url, authData)
      .then(res => {
        const expirationDate = new Date(
          new Date().getTime() + res.data.expiresIn * 1000
        )

        dispatch(
          authSuccess(
            res.data.idToken,
            res.data.localId,
            expirationDate,
            res.data.refreshToken
          )
        )
        dispatch(
          actions.userSave(res.data.localId, res.data.idToken, name, email)
        )
        dispatch(checkAuthTimeout(res.data.expiresIn - 60))
      })
      .catch(err => {
        dispatch(authFail(err.response.data.error))
      })
  }
}

export const refreshToken = refreshToken => {
  return dispatch => {
    const authData = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    }

    const url =
      'https://securetoken.googleapis.com/v1/token?key=AIzaSyBHfcEH7VA616uzPkDIi8pm6hZvwxOvFzk'

    axios
      .post(url, authData)
      .then(res => {
        const expirationDate = new Date(
          new Date().getTime() + (res.data.expires_in - 60) * 1000
        )

        dispatch(
          authSuccess(
            res.data.id_token,
            res.data.user_id,
            expirationDate,
            res.data.refresh_token
          )
        )
        dispatch(
          checkAuthTimeout(res.data.expires_in - 60, res.data.refresh_token)
        )
      })
      .catch(err => {
        console.log(`[refreshToken err]`, err)

        dispatch(authFail(err.response.data.error))
      })
  }
}

export const signin = (email, password) => {
  return dispatch => {
    dispatch(authStart())

    const authData = {
      email: email,
      password: password,
      returnSecureToken: true
    }

    const url =
      'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBHfcEH7VA616uzPkDIi8pm6hZvwxOvFzk'

    axios
      .post(url, authData)
      .then(res => {
        const expirationDate = new Date(
          new Date().getTime() + (res.data.expiresIn - 60) * 1000
        )

        dispatch(
          authSuccess(
            res.data.idToken,
            res.data.localId,
            expirationDate,
            res.data.refreshToken
          )
        )
        dispatch(actions.userFetch(res.data.localId, res.data.idToken))
        dispatch(
          checkAuthTimeout(res.data.expiresIn - 60, res.data.refreshToken)
        )
      })
      .catch(err => {
        dispatch(authFail(err.response.data.error))
      })
  }
}

export const logout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const checkAuthTimeout = (expirationTime, refresh_token) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(refreshToken(refresh_token))
    }, expirationTime * 1000)
  }
}

export const authCheckState = () => {
  return dispatch => {
    const restoredState = loadState()

    if (restoredState) {
      dispatch(restoreState(restoredState))

      const token = restoredState.auth.token

      if (token) {
        const expirationDate = new Date(restoredState.auth.expirationDate)
        const refreshToken = restoredState.auth.refreshToken

        if (expirationDate > new Date()) {
          dispatch(
            checkAuthTimeout(
              (expirationDate.getTime() - new Date().getTime()) / 1000,
              refreshToken
            )
          )
        } else {
          dispatch(logout())
        }
      }
    }
  }
}

export const restoreState = state => {
  return {
    type: actionTypes.RESTORE_STATE,
    state: state
  }
}

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAILED,
    error: error
  }
}

export const authEraseError = () => {
  return {
    type: actionTypes.AUTH_ERASE_ERROR,
    error: null
  }
}
