import React from 'react'
import classes from './Badge.module.css'
import Badge from '@material-ui/core/Badge'
import { connect } from 'react-redux'

const CustomBadge = props => {
  const now = Date.now()
  const day = 24 * 60 * 60 * 1000
  const daysLeft =
    props.paidAccessUntil < now
      ? 0
      : Math.ceil((props.paidAccessUntil - now) / day)

  let cl = [classes.Badge]

  switch (true) {
    case daysLeft >= 7:
      cl.push(classes.Green)
      break
    case daysLeft <= 6 && daysLeft > 1:
      cl.push(classes.Yellow)
      break
    case daysLeft === 1 || daysLeft >= 0:
      cl.push(classes.Red)
      break
    default:
      cl.push(classes.Hidden)
      break
  }

  return (
    <Badge badgeContent={daysLeft} className={cl.join(' ')} showZero max={999}>
      {props.children}
    </Badge>
  )
}

const mapStateToProps = state => {
  return {
    paidAccessUntil: state.user.paidAccessUntil
  }
}

export default connect(mapStateToProps, null)(CustomBadge)
