import React from 'react'
import classes from './Logo.module.css'
import { Link } from 'gatsby'
import logoImg from '../../images/logoHQ/logo_white.svg'

export default props => (
  <div className={classes.Logo}>
    <Link to="/">
      <img src={logoImg} alt="ErudiCAT" />
    </Link>
  </div>
)
