import axios from '../../utils/axios'
import prices from '../../utils/prices/prices'
import { info } from '../../utils/prices/prices'

export const paymentSave = (userId, token, date, days) => {
  return () => {
    const data = {
      type: info.type,
      date: date,
      amount: prices.filter(p => p.days === days)[0].price,
      days: days
    }

    axios
      .post(`/payments/${userId}.json?auth=${token}`, data)
      .then(res => {
        console.log(`[payment] res`, res)
      })
      .catch(err => {
        console.log(`[payment] err`, err)
      })
  }
}
