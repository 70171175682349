import * as actionTypes from './actionTypes'

export const fieldLock = field => {
  return {
    type: actionTypes.FIELD_LOCK,
    field: field
  }
}

export const fieldRelease = () => {
  return {
    type: actionTypes.FIELD_RELEASE
  }
}
