import * as actionTypes from './actionTypes'
import axios from '../../utils/axios'

export const resultSave = (userId, token, result) => {
  return dispatch => {
    dispatch(resultSaving())

    axios
      .post(`/statistics/${userId}.json?auth=` + token, result)
      .then(_ => {
        dispatch(examSuccessMessage())
      })
      .catch(err => {
        console.log('resultSave err', err)

        // TODO: handle
      })
  }
}

export const examSuccessMessage = result => {
  return {
    type: actionTypes.EXAM_SUCCESS_MESSAGE
  }
}

export const resultFetch = (userId, token) => {
  return dispatch => {
    axios
      .get(`/statistics/${userId}.json?auth=` + token)
      .then(res => {
        dispatch(
          resultFetchSuccess(res.data ? Object.keys(res.data).length : 0)
        )

        if (res.data) {
          for (let k in res.data) dispatch(resultSaveSuccess(res.data[k]))
        }
      })
      .catch(err => {
        console.log('resultFetch err', err)

        // TODO: handle
      })
  }
}

export const resultSaveSuccess = result => {
  return {
    type: actionTypes.STATISTICS_RESULT_SAVE_SUCCESS,
    result: result
  }
}

export const resultFetchSuccess = examsCount => {
  return {
    type: actionTypes.STATISTICS_RESULT_FETCH_SUCCESS,
    examsCount: examsCount
  }
}

export const resultSaving = () => {
  return {
    type: actionTypes.STATISTICS_RESULT_SAVING
  }
}
