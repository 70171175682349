import React, { Component } from 'react'
import classes from './NavigationItems.module.css'
import NavigationItem from './NavigationItem/NavigationItem'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index'
import Badge from './Badge/Badge'

class NavigationItems extends Component {
  handleOnLogout = () => {
    this.props.onLogout()
  }

  render() {
    let navItems = (
      <>
        <NavigationItem link="/pmp-training-sign-in">Sign In</NavigationItem>
        <NavigationItem link="/pmp-exam-simulator-sign-up">
          Sign Up
        </NavigationItem>
      </>
    )

    if (this.props.user.name) {
      navItems = (
        <>
          <NavigationItem link="/pmp-exam-simulator-statistics">
            Statistics
          </NavigationItem>
          <NavigationItem link="/pmp-preparation-profile">
            <Badge>{this.props.user.name}</Badge>
          </NavigationItem>
          <NavigationItem link="/fields">Fields</NavigationItem>
          <NavigationItem link="/" click={this.handleOnLogout}>
            Log Out
          </NavigationItem>
        </>
      )
    }

    return (
      <ul className={classes.NavigationItems}>
        <NavigationItem link="/">Home</NavigationItem>
        <NavigationItem link="/pmp-blog">Blog</NavigationItem>
        <NavigationItem link="/pmp-vocabulary">Vocabulary</NavigationItem>
        {navItems}
      </ul>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationItems)
