import React from 'react'
import classes from './DrawerToggle.module.css'

export default props => (
  <div
    className={classes.DrawerToggle}
    onClick={props.clicked}
    onKeyDown={() => {}}
    role="button"
    tabIndex={0}
    aria-label="DrawerToggle"
  >
    <div></div>
    <div></div>
    <div></div>
  </div>
)
