import React from 'react'
import { Link } from 'gatsby'
import classes from './NavigationItem.module.css'

export default props => (
  <Link to={`/${props.link}`}>
    <div className={classes.Item}>
      <span>{props.text}</span>
    </div>
  </Link>
)
