import React from 'react'
import classes from './StartStudying.module.css'
import { Link } from 'gatsby'
import Button from '@material-ui/core/Button'
import getRandomQuestion from '../../../utils/question/getRandom'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index'

const startStudying = props => {
  const questionURL = getRandomQuestion().url

  const click = () => {
    if (props.lockedField) props.onFieldRelease()
  }

  return (
    <div className={classes.Start}>
      <Link to={`/${questionURL}`}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#5fba7d', color: '1C132A' }}
          onClick={click}
        >
          PRACTICE
        </Button>
      </Link>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lockedField: state.field.locked
  }
}

const mapActionToProps = dispatch => {
  return {
    onFieldRelease: () => dispatch(actions.fieldRelease())
  }
}

export default connect(mapStateToProps, mapActionToProps)(startStudying)
