import * as actionTypes from './actionTypes'
import settings from '../../utils/exam/settings'

export const examStart = (exam, duration, deadline, questionsToAnswer) => {
  let distance = deadline - Date.now()
  let acceleration = distance - distance * (1 - settings.acceleration)
  const acceleratedDeadline = deadline - acceleration

  return {
    type: actionTypes.EXAM_START,
    exam: exam,
    duration: duration,
    deadline: deadline,
    questionsToAnswer: questionsToAnswer,
    examSuccess: false,
    acceleratedDeadline: acceleratedDeadline
  }
}

export const examStop = () => {
  return {
    type: actionTypes.EXAM_STOP
  }
}

export const questionAnswer = (field, questionURL, isCorrect) => {
  return {
    type: actionTypes.QUESTION_ANSWER,
    field: field,
    questionURL: questionURL,
    isCorrect: isCorrect
  }
}

export const examEraseSuccessMessage = () => {
  return {
    type: actionTypes.EXAM_ERASE_SUCCESS_MESSAGE
  }
}

export const examDisplayCorrectAnswer = () => {
  return {
    type: actionTypes.EXAM_DISPLAY_CORRECT_ANSWER
  }
}

export const examAccelerationChanged = acceleration => {
  return {
    type: actionTypes.EXAM_ACCELERATION_CHANGED,
    acceleration: acceleration
  }
}
