import React from 'react'
import classes from './Footer.module.css'
import Separator from './Separator/Separator'
import NavigationItem from './NavigationItem/NavigationItem'

const footer = () => {
  return (
    <footer className={classes.Footer}>
      <div className={classes.WhatWeDo}>
        This website has PMP Exam Simulator, 1k+ Sample Questions, Performance
        Chart and Detailed Statistics. Use PMP Mock Exam to Prepare for PMP
        Certification.
      </div>
      <div className={classes.FooterToolbar}>
        <NavigationItem link="project-manager-about" text="About Us" />
        <Separator />
        <NavigationItem link="project-manager-contact" text="Contact Us" />
        <Separator />
        <NavigationItem link="pmp-exam-faq" text="FAQ" />
        <Separator />
        <NavigationItem
          link="pmp-training-privacy-policy"
          text="Privacy Policy"
        />
        <Separator />
        <NavigationItem link="pmp-training-disclaimer" text="Disclaimer" />
        <Separator />
        <NavigationItem
          link="pmp-sample-questions-terms-of-use"
          text="Terms of Use"
        />
      </div>
      <div className={classes.CopyRight}>
        © {new Date().getFullYear()}, Created by
        {` `}
        <a
          href="https://tqcoders.com"
          rel="noopener noreferrer"
          target="_blank"
          className={classes.Link}
        >
          Web Development Company
        </a>
        &nbsp;
        <a
          href="https://tqcoders.com/web-development-about"
          rel="noopener noreferrer"
          target="_blank"
          className={classes.Link}
          style={{ fontWeight: '600' }}
        >
          tqCoders
        </a>
      </div>
      <div className={classes.CopyRightPMP}>
        The Project Management Professional (PMP) and PMBOK are registered marks
        of the Project Management Institute, Inc.
      </div>
    </footer>
  )
}

export default footer
